import { ApiConfig } from './api-config';

export class ApiBase {
  protected constructor(private apiConfig: ApiConfig) {}

  protected async transformOptions(options: any): Promise<any> {
    const accessToken = await this.apiConfig.getAccessToken();

    options.headers = options.headers.append(
      'authorization',
      `Bearer ${accessToken}`,
    );

    return Promise.resolve(options);
  }
}
